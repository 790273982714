import * as React from "react";
import * as styles from "./About.module.scss";
import Container from "../../components/container/container";
import AboutImage from "../../images/about.png";

const About = ({}) => {
  return (
    <section className={`${styles.about}`}>
      <Container>
        <div className={styles.aboutWrap}>
          <div className={styles.aboutLeft}>
            <div className={styles.aboutImage}>
              <div className={styles.aboutImageWrap}>
                <img src={AboutImage} alt="" />
              </div>
            </div>
          </div>
          <div className={styles.aboutRight}>
            <h2>
              Our team is supported by a community of security enthusiasts,
              developing increasingly sophisticated exploit-spotting tools on
              top of the <span>Lossless protocol</span>
            </h2>
            <p>
              Lossless provides bleeding-edge Web3 security framework to
              selected projects. We continuously push the limits of what’s
              possible and reinvest a substantial share of our profits into R&D
              to find new innovative ways to protect our clients.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
