import * as React from "react";
import * as styles from "./TeamMemberCard.module.scss";

const TeamMember = ({ image, fullName, position, socials }) => {
  return (
    <div className={styles.item}>
      <div className={styles.itemImage}>{image}</div>
      <p className={styles.itemLabel}>{position}</p>
      <div className={styles.itemBottom}>
        <h3 className={styles.itemName}>{fullName}</h3>
        {typeof socials !== "undefined" && (
          <div className={styles.itemSocials}>
            {socials.map((social, index) => (
              <a
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {social.icon}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMember;
