import * as React from "react";
import Layout from "../components/layout/layout";
import LottieAnimation from "../components/lootie/Lottie";
import FadeIn from "../components/fade/fade";
import Hero from "../sections/hero/hero";
import BlocksWithIconsTwo from "../sections/blocksWithIconsTwo/BlocksWithIconsTwo";
import Partners from "../sections/partners/Partners";
import About from "../sections/about/About";
import CustomButton from "../components/customButton/CustomButton";
import Team from "../sections/team/Team";
import Cta from "../sections/cta/Cta";
// import Image from "../images/about-hero.jpg";
import HeroVideo from "../videos/about.mp4";

import PrivateKeyTheftJson from "../json/Private_Key_Theft.json";
import SmartContractExploitsJson from "../json/Smart_Contract_Exploits.json";

const ctaData = {
  title: "Join Lossless team",
  text: "Have you got the skills to help the DeFi landscape evolve? Let’s talk!",
  button: (
    <CustomButton type="blur" size="big" text="Coming soon" disabled={true} />
  ),
};

const sectionWithTwoBlocksData = {
  title:
    "Created to foster a more mature, reputable, and <span>secure Web3</span>, preventing financial loss arising from:",
  align: "left",
  titleMaxWidth: "73.6rem",
  background: true,
  items: [
    {
      title: "Private Key Theft",
      text: "Lossless protocol acts as a safety net for token holders, protecting them from the loss of funds associated with having their private keys compromised.",
      icon: <LottieAnimation animationJson={PrivateKeyTheftJson} />,
    },
    {
      title: "Smart Contract Exploits",
      text: "Lossless protocol implements a layer of security against smart contract vulnerabilities and the immutable nature of the blockchain that malicious actors attempt to take advantage of.",
      icon: <LottieAnimation animationJson={SmartContractExploitsJson} />,
    },
  ],
};

const AboutPage = () => {
  return (
    <Layout pageTitle="About - Lossless">
      <Hero
        title="Re-Inspiring Confidence in Web3"
        text="Lossless was designed to foster blockchain adoption by guaranteeing investor confidence and restoring trust in Web3 security architecture."
        // backgroundUrl={Image}
        titleMaxWidth="84.4rem"
        video={HeroVideo}
      />
      <FadeIn>
        <BlocksWithIconsTwo
          items={sectionWithTwoBlocksData.items}
          align={sectionWithTwoBlocksData.align}
          title={sectionWithTwoBlocksData.title}
          text={sectionWithTwoBlocksData.text}
          button={sectionWithTwoBlocksData.button}
          background={sectionWithTwoBlocksData.background}
          titleMaxWidth={sectionWithTwoBlocksData.titleMaxWidth}
        />
      </FadeIn>
      <FadeIn>
        <About />
      </FadeIn>
      <FadeIn>
        <Team />
      </FadeIn>
      <FadeIn>
        <Cta
          title={ctaData.title}
          text={ctaData.text}
          button={ctaData.button}
        />
      </FadeIn>
      <FadeIn>
        <Partners />
      </FadeIn>
    </Layout>
  );
};

export default AboutPage;
