import * as React from "react";
import * as styles from "./Team.module.scss";
import Container from "../../components/container/container";
import TeamMember from "../../components/teamMemberCard/TeamMemberCard";
import { StaticImage } from "gatsby-plugin-image";
import LinkedinLogo from "../../svg/linkedin.svg";
import TwitterLogo from "../../svg/twitter.svg";

const teamMembers = [
  {
    fullName: "Vygandas Masilionis",
    position: "Project lead",
    image: (
      <StaticImage
        src="../../images/team/vygandas.jpg"
        alt="Vygandas Masilionis"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/vygandas-masilionis-717192a7/",
      },
    ],
  },
  {
    fullName: "Domantas Pelaitis",
    position: "Technology lead",
    image: (
      <StaticImage
        src="../../images/team/domantas.jpg"
        alt="Domantas Pelaitis"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/domantaspelaitis/",
      },
    ],
  },
  {
    fullName: "Dominykas van Otterlo",
    position: "Business development lead",
    image: (
      <StaticImage
        src="../../images/team/dominykas.jpg"
        alt="Dominykas van Otterlo"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/dodovano/",
      },
    ],
  },
  {
    fullName: "Monika Sviderskė",
    position: "Marketing lead",
    image: (
      <StaticImage src="../../images/team/monika.jpg" alt="Monika Sviderskė" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/monika-poskute/",
      },
      {
        icon: <TwitterLogo />,
        url: "https://twitter.com/monika_svdrsk",
      },
    ],
  },
  {
    fullName: "Ignacio Freire",
    position: "Technology",

    image: (
      <StaticImage src="../../images/team/ignacio.jpg" alt="Ignacio Freire" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/ignacio-freire-531a2051/",
      },
    ],
  },
  {
    fullName: "Luciano Lupo",
    position: "Technology",

    image: (
      <StaticImage src="../../images/team/luciano.jpg" alt="Luciano Lupo" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/lucianolupo/",
      },
    ],
  },
  {
    fullName: "Matas Povilauskas",
    position: "Operations",
    linkdin: "https://www.linkedin.com/in/matas-povilauskas/",
    image: (
      <StaticImage src="../../images/team/matas.jpg" alt="Matas Povilauskas" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/matas-povilauskas/",
      },
    ],
  },
  {
    fullName: "Arūnas Žežickas",
    position: "Technology",
    image: (
      <StaticImage src="../../images/team/arunas.jpg" alt="Arūnas Žežickas" />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/ar%C5%ABnas-%C5%BEe%C5%BEickas-745500227/",
      },
    ],
  },
  {
    fullName: "Martin",
    position: "Technical copywriter",
    image: <StaticImage src="../../images/team/martin.jpg" alt="Martin" />,
    socials: [
      {
        icon: <TwitterLogo />,
        url: "https://twitter.com/Fundonomics",
      },
    ],
  },
];
const strategicAdvisors = [
  {
    fullName: "Jogundas Armaitis",
    position: "Oxipit, Lithuania.ai",
    image: (
      <StaticImage
        src="../../images/team/jogundas.jpg"
        alt="Jogundas Armaitis"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/jogundas/",
      },
    ],
  },
  {
    fullName: "Pascal Marco Caversaccio",
    position: "White-hat hacker",
    image: (
      <StaticImage
        src="../../images/team/pascal.jpg"
        alt="Pascal Marco Caversaccio"
      />
    ),
    socials: [
      {
        icon: <LinkedinLogo />,
        url: "https://www.linkedin.com/in/pascalmarcocaversaccio/",
      },
    ],
  },
];

const Team = () => {
  return (
    <section className={styles.team}>
      <Container>
        <div className={styles.teamWrap}>
          <div className={styles.teamSection}>
            <div className={`${styles.teamSectionHeader}`}>
              <h2>Our team</h2>
              <p>
                Our group of experienced cybersecurity specialists, blockchain
                engineers, finance professionals, and white-hat hackers are
                united in their pursuit to mitigate the financial impact of
                exploits and malicious cyber activity.
              </p>
            </div>

            <div className={styles.teamMembers}>
              {teamMembers.map((member, index) => (
                <TeamMember
                  key={index}
                  data={member}
                  fullName={member.fullName}
                  position={member.position}
                  image={member.image}
                  linkedin={member.linkdin}
                  twitter={member.twitter}
                  socials={member.socials}
                />
              ))}
            </div>
          </div>
          <div className={styles.teamSection}>
            <div
              className={`${styles.teamSectionHeader} ${styles.teamSectionHeaderSmall}`}
            >
              <h2>Strategic Advisors</h2>
            </div>

            <div className={styles.teamMembers}>
              {strategicAdvisors.map((member, index) => (
                <TeamMember
                  key={index}
                  data={member}
                  fullName={member.fullName}
                  position={member.position}
                  image={member.image}
                  linkedin={member.linkdin}
                  twitter={member.twitter}
                  socials={member.socials}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Team;
