import * as React from "react";
import * as styles from "./Cta.module.scss";
import Container from "../../components/container/container";
import CtaBackground from "../../images/cta.jpg";

const Cta = ({ title, text, button }) => {
  return (
    <section
      className={styles.cta}
      style={{ backgroundImage: `url(${CtaBackground})` }}
    >
      <Container>
        <h2>{title}</h2>
        <p>{text}</p>
        <div className={styles.ctaButton}>{button}</div>
      </Container>
    </section>
  );
};

export default Cta;
