import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Grid } from "swiper";
import * as styles from "./Partners.module.scss";
import Container from "../../components/container/container";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";

import PoolzVenturesLogo from "../../images/partners/poolz-ventures.png";
import CyberfiLogo from "../../images/partners/cyberfi.png";
import LaunchxLogo from "../../images/partners/launchx.png";
import UnoreLogo from "../../images/partners/unore.png";
import PolkaFoundryLogo from "../../images/partners/polkafoundry.png";
import NewsCryptoLogo from "../../images/partners/newscrypto.png";
import DaoMakerLogo from "../../images/partners/daomaker.png";
import MasterVenturesLogo from "../../images/partners/masterventures.png";
import x21Logo from "../../images/partners/x21.png";
import MagniusCapitalLogo from "../../images/partners/magnuscapital.png";
import HackenLogo from "../../images/partners/hacken.png";
import BounceLogo from "../../images/partners/bounce.png";
import PaidLogo from "../../images/partners/paid.png";

const partners = [
  {
    image: PoolzVenturesLogo,
  },
  {
    image: CyberfiLogo,
  },
  {
    image: LaunchxLogo,
  },
  {
    image: UnoreLogo,
  },
  {
    image: PolkaFoundryLogo,
  },
  {
    image: NewsCryptoLogo,
  },
  {
    image: DaoMakerLogo,
  },
  {
    image: MasterVenturesLogo,
  },
  {
    image: x21Logo,
  },
  {
    image: MagniusCapitalLogo,
  },
  {
    image: HackenLogo,
  },
  {
    image: BounceLogo,
  },
  {
    image: PaidLogo,
  },
];

const Partners = ({}) => {
  return (
    <section className={`${styles.partners}`}>
      <Container>
        <h2>Our Partners</h2>
      </Container>
      <div className={styles.partnersSwiper}>
        <Swiper
          modules={[Navigation, Autoplay, Grid]}
          spaceBetween={16}
          slidesPerView="auto"
          grabCursor="true"
          loop={true}
          speed={2000}
          autoplay={{
            delay: null,
            disableOnInteraction: false,
          }}
          breakpoints={{
            767: {
              spaceBetween: 32,
            },
            1024: {
              spaceBetween: 32,
            },
          }}
        >
          {partners.map((partner, index) => (
            <SwiperSlide className={styles.partnersItem} key={index}>
              <img src={partner.image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
